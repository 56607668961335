<template>
  <div class="wp100">
    <a-button type="primary" @click="search">
      查询
    </a-button>
    <a-button @click="reset">
      重置
    </a-button>
  </div>
</template>

<script>
export default {
  name: 'searchAndReset',
  methods: {
    search() {
      this.$emit('handleSearch');
    },
    reset() {
      this.$emit('getData');
    },
  },
};
</script>

<style scoped>
.wp100 {
  width: 100%;
}
</style>
